import { template as template_82a6b00634034caabc08562d033abc64 } from "@ember/template-compiler";
const FKText = template_82a6b00634034caabc08562d033abc64(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
