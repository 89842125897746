import { template as template_2b3c4a09f8344bdd8e56fca78a7f17ba } from "@ember/template-compiler";
const FKControlMenuContainer = template_2b3c4a09f8344bdd8e56fca78a7f17ba(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
